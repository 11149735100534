import React, { useState, useEffect } from "react";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { useAlert } from "react-alert";
import { pdf } from "@react-pdf/renderer";
import QRCode from "qrcode";
import { useTranslation } from "react-i18next";

import { db } from "../firebase";
import api_key from "../env";
import default_org from "./img/org_default.png";
import facebook from "./img/facebook.svg";
import instagram from "./img/instagram.svg";
import youtube from "./img/youtube.svg";
import twitter from "./img/twitter.svg";

import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { getOrganization } from "../utility";
import ENV from "../env";
import Input from "./Input";
import TicketItem from "./TicketItem";
import LoadingSpinner from "../components/LoadingSpinner";
import ClosedSpinner from "../components/ClosedSpinner";
import CheckBox from "../components/CheckBox";
import Dropdown from "./Dropdown";
import {
  sendQRCode,
  createParticipant,
  createStripePayment,
  createStripeFreePayment,
} from "../utility";
import TicketModel from "./pdf";
import * as moment from "moment";
import "moment/locale/fr";
import { event } from "jquery";

moment.locale("fr-FR");

const socialLinks = {
  facebook: "facebook",
  twitter: "twitter",
  youtube: "youtube",
  instagram: "instagram",
};

const EventDesc = (props) => {
  const { t, i18n } = useTranslation("eventdesc");
  const [isThrottled, setIsThrottled] = useState(false);
  const isOpen = props.event?.open ?? false;
  const eventId = props.event?.id ?? "";
  const orgId = props.event?.orgId ?? "";
  const feeDelegated = props.event?.feeDelegated ?? false;
  const ticketSubdivision = props.event?.subdivision ?? false;
  const endDate = new Date(props.event?.endDate ?? new Date());

  const ansQues = props.event?.ansQues ?? [];

  const [description, setDescription] = useState(
    props.event?.descritption ?? ""
  );
  const [organization, setOrganization] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPromoAmount, setTotalPromoAmount] = useState(0);
  const [numTickets, setNumTickets] = useState(0);
  const [fields, setFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [needVat, setNeedVat] = useState(false);
  const [vatInvoiceAddress, setVatInvoiceAddress] = useState("");
  const [enteredPromoCode, setEnteredPromoCode] = useState("");
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [vatCompanyName, setVatCompanyName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [placeCoordinates, setPlaceCoordinates] = useState({
    lat: 50.850245,
    lng: 4.349689,
  });
  const [eventAddress, setEventAddress] = useState("");

  const alert = useAlert();
  var moment = require("moment");

  const saleEnded = moment(endDate).isBefore();

  const loadTickets = async () => {
    const ticketsRef = collection(db, "Events", eventId, "Tickets");
    let loadedTickets;

    await getDocs(ticketsRef)
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        loadedTickets = data;
      })
      .catch((err) => {
        console.log(t("fetch_tickets_failed") + err);
      });

    setTickets(loadedTickets);
  };

  const setTicketsHandler = (newTickets) => {
    setSelectedTickets(newTickets);
    let newNumTickets = 0;
    let newTotalAmount = 0;

    newTickets.forEach((item) => {
      newNumTickets += parseInt(item.number);

      let ticketTotalPrice = parseFloat(item.sum);

      // Vérifier si le ticket a un code promo
      if (item.promoCodeName && enteredPromoCode === item.promoCodeName) {
        const discount =
          (ticketTotalPrice * parseFloat(item.promoCodeDiscount)) / 100;
        ticketTotalPrice -= discount; // Appliquer la réduction
      }

      newTotalAmount += ticketTotalPrice;
    });

    setNumTickets(newNumTickets);
    setTotalAmount(newTotalAmount);
  };

  const saveForm = (e) => {
    e.preventDefault();

    const customFieldsLength = props.event.formFields.length + 1;

    if (fields.length < customFieldsLength) {
      alert.show(t("fill_all_form_fields"));
    } else {
      alert.show(t("form_complete"), { type: "success" });
    }
  };

  const inputChangeHandler = (changeEvent) => {
    const existingField = fields.findIndex(
      (element) => element.name === changeEvent.target.id
    );

    const newFields = fields;
    if (existingField >= 0) {
      newFields[existingField] = {
        name: changeEvent.target.id,
        value: changeEvent.target.value,
      };
    } else {
      newFields.push({
        name: changeEvent.target.id,
        value: changeEvent.target.value,
      });
    }
    setFields(newFields);
  };

  const customFields = props.event.formFields.map((field) => {
    switch (field) {
      case "Name":
        return (
          <div className="col-12">
            <Input
              placeholder={t("add_name")}
              label={t("name")}
              type="text"
              id="Name"
              onChange={(e) => inputChangeHandler(e)}
              required="required"
            />
          </div>
        );
      case "Email":
        return (
          <div className="col-12">
            <Input
              placeholder={t("email_placeholder")}
              label={t("email")}
              type="text"
              id="Email"
              onChange={(e) => inputChangeHandler(e)}
              required="required"
            />
          </div>
        );
      case "Address":
        return (
          <div className="col-12">
            <Input
              placeholder={t("address_placeholder")}
              label={t("address")}
              type="text"
              id="Address"
              onChange={(e) => inputChangeHandler(e)}
              required="required"
            />
          </div>
        );
      case "Phone Number":
        return (
          <div className="col-12">
            <label className="form-label __form-label" htmlFor="phone number">
              {t("phone_number")}
            </label>
            <div className="d-flex" style={{ gap: "15px" }}>
              <input
                className="form-control __form-control w-0 flex-grow"
                placeholder={t("phone_number_placeholder")}
                type="text"
                id="Phone Number"
                required="required"
                onChange={(e) => inputChangeHandler(e)}
              />
            </div>
          </div>
        );
      default:
        return (
          <div className="col-12">
            <Input
              placeholder={t("add_field", { field })}
              label={field}
              required="required"
              type="text"
              id={field}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
        );
    }
  });

  const checkoutHandler = async (paymentMethod) => {
    const customFieldsLength = props.event.formFields.length + 1;

    if (numTickets === 0) {
      alert.show(t("select_at_least_one_ticket"), {
        type: "error",
      });
    } else if (fields.length < customFieldsLength) {
      setIsLoading(true);
      alert.show(t("fill_all_form_fields"), {
        type: "error", //
      });
    } else {
      setIsLoading(true);
      const participantField = fields.find(
        (element) => element.name === "Email"
      );
      const participantMail = participantField.value.trim();
      const participantPhoneNumber = fields.find(
        (element) => element.name === "Phone Number"
      );
      if (!participantMail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        alert.show(t("invalid_email"), { type: "error" });
      } else if (
        participantPhoneNumber &&
        !participantPhoneNumber.value.startsWith("+")
      ) {
        alert.show(t("enter_phone_with_prefix"), { type: "error" });
      } else {
        if (totalAmount === 0) {
          await createParticipant(
            eventId,
            selectedTickets,
            fields,
            ticketSubdivision
          )
            .then(async (participantId) => {
              alert.show(t("registration_successful"), { type: "success" });
              window.open(
                `${ENV.hostname}/checkout/${eventId}/${participantId}/success`,
                "_parent",
                "noreferrer"
              );
            })
            .catch((err) => {
              alert.show(t("registration_failed"), {
                type: "error",
              });
              console.log(t("failed_to_save_participant") + err);
            });
        } else {
          await createStripePayment(
            promoCodeApplied ? totalPromoAmount : totalAmount,
            selectedTickets,
            fields,
            participantMail,
            orgId,
            eventId,
            paymentMethod,
            feeDelegated,
            ticketSubdivision
          )
            .then(async () => {
              alert.show(t("payment_successful"), { type: "success" });
            })
            .catch((err) => {
              alert.show(t("payment_failed"), { type: "error" });
              console.log(t("failed_to_save_participant") + err);
            });
        }
      }
    }

    setIsLoading(false);
  };

  const showOnMap = () => {
    window.open(
      `https://maps.google.com?q=${placeCoordinates.lat},${placeCoordinates.lng}`,
      "_blank"
    );
  };

  const openSocial = (link) => {
    const url = `https://ticket.comitty.be/events/${eventId}`;

    switch (link) {
      case socialLinks.youtube:
        window.open("https://www.youtube.com", "_blank");
        break;
      case socialLinks.twitter:
        window.open(`https://twitter.com/intent/tweet?text=${url}`, "_blank");
        break;
      case socialLinks.facebook:
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          "_blank"
        );
        break;
      case socialLinks.instagram:
        window.open(`https://www.instagram.com`, "_blank");
        break;
      default:
        window.open("https://www.google.com", "_blank");
        break;
    }
  };

  const addPromo = () => {
    // If throttled, prevent further execution
    if (!enteredPromoCode) {
      return;
    }
    if (isThrottled) {
      alert.show(t("please_wait"), { type: "warning" });
      return;
    }

    // Throttle function execution by 1 second
    setIsThrottled(true);
    setTimeout(() => setIsThrottled(false), 1000);

    // Find tickets that match the entered promo code
    const matchingTickets = tickets.filter(
      (ticket) => ticket.promoCodeName === enteredPromoCode
    );

    // Check if any tickets match the promo code
    if (matchingTickets.length === 0) {
      alert.show(t("promo_code_not_exist"), {
        type: "error",
      });
      setPromoCodeApplied(false);
      return;
    }

    if (promoCodeApplied === enteredPromoCode) {
      alert.success(t("promo_code_already_applied"), {
        type: "info",
      });
      return;
    }

    alert.success(t("promo_code_applied"), {
      type: "success",
    });
    setPromoCodeApplied(enteredPromoCode);
  };

  const organizationData = async (orgId) => {
    const orgData = await getOrganization(orgId);
    setOrganization(orgData);
  };

  useEffect(() => {
    if (props.event) {
      loadTickets();
      if (props?.event?.eventLocation) {
        console.log(t("set_place"));
        setPlaceCoordinates({
          lat: props.event.eventLocation.latitude,
          lng: props.event.eventLocation.longitude,
        });
      }
      if (props?.event?.eventAddress) {
        console.log(t("set_address"));
        setEventAddress(props.event.eventAddress);
      }

      organizationData(orgId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  useEffect(() => {
    if (props?.event?.descEN) {
      switch (i18n.language) {
        case "en": {
          setDescription(props.event.descEN);
          break;
        }
        case "fr": {
          setDescription(props.event.descFR);
          break;
        }
        case "nl": {
          setDescription(props.event.descNL);
          break;
        }
        default: {
          setDescription(props.event.descEN);
        }
      }
    } else {
      setDescription(props.event.description);
    }
  }, [i18n.language]);

  return (
    <section className="event-desc-section">
      <div className="container">
        <div className="section-header">
          <h2 className="title">
            {props.event?.noTitle ? props.event.name : t("description")}
          </h2>
          <div className="text">
            {props.event
              ? description.split("\n").map((i, key) => {
                  return <div key={key}>{i}</div>;
                })
              : ""}
          </div>
        </div>
        {(!isOpen || saleEnded) && (
          <div className="section-header">
            <h2 className="title">{t("sale_closed")}</h2>
            <ClosedSpinner height="50%" width="50%" />
            <div className="col-12">
              <button
                type="submit"
                className="submitBtn"
                onClick={() => {
                  window.open(`https://comitty.be`, "_parent", "noreferrer");
                }}
              >
                {t("return_to_comitty")}
              </button>
            </div>
          </div>
        )}
        {isOpen && !saleEnded && (
          <div className="__details-wrapper">
            <form className="__details-form" onSubmit={saveForm}>
              <h3 className="__details-form-title">{t("fill_the_form")}</h3>
              <div className="row g-2 g-lg-3">
                <div className="col-6">
                  <Input
                    placeholder={t("add_firstname")}
                    label={t("firstname")}
                    type="text"
                    id="Firstname"
                    onChange={(e) => inputChangeHandler(e)}
                    required="required"
                  />
                </div>
                <div className="col-6">
                  <Input
                    placeholder={t("add_lastname")}
                    label={t("lastname")}
                    type="text"
                    id="Name"
                    onChange={(e) => inputChangeHandler(e)}
                    required="required"
                  />
                </div>
                <div className="col-12">
                  <Input
                    placeholder={t("email_placeholder")}
                    label={t("email")}
                    type="text"
                    id="Email"
                    onChange={(e) => inputChangeHandler(e)}
                    required="required"
                  />
                  <div className="text-sm mt-2">
                    <span className="me-1">
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 5.05285L9.43152 3.12873L5.83979 4.48374L5.96899 0.5H4.05685L4.16021 4.45664L0.594315 3.12873L0 5.05285L3.64341 6.16396L1.31783 9.30759L2.86822 10.5L5.01292 7.16667L7.15762 10.5L8.70801 9.30759L6.38243 6.16396L10 5.05285Z"
                          fill="#EB5757"
                        />
                      </svg>
                    </span>
                    {t("valid_email_warning")}
                  </div>
                </div>
                {customFields}

                {/* Afficher les questions avec dropdown pour les réponses */}
                {ansQues?.length > 0 &&
                  ansQues.map((questionObj, questionIndex) => (
                    <div
                      className="col-12 question-container"
                      key={questionIndex}
                    >
                      <Dropdown
                        options={questionObj.answers.map((answer) => ({
                          value: answer.text,
                          label: answer.text,
                        }))}
                        value={null} // Initialement aucune réponse n'est sélectionnée
                        label={questionObj.question}
                        onChange={(selectedOption) =>
                          inputChangeHandler({
                            target: {
                              id: questionObj.question,
                              value: selectedOption.value,
                            },
                          })
                        }
                      />
                    </div>
                  ))}
                {/* end question */}

                <div className="col-12">
                  <button type="submit" className="submitBtn">
                    {t("validate")}
                  </button>
                </div>
              </div>
            </form>
            {tickets.length ? (
              <div className="__ticket-wrapper">
                <h2 className="__ticket-wrapper-title">
                  {t("choose_your_tickets")}
                </h2>
                {tickets.length > 10
                  ? tickets.map((ticket) => (
                      <TicketItem
                        discount={props.event?.promoCodeDiscount}
                        promoCodeEnabled={promoCodeApplied}
                        ticket={ticket}
                        totalAmount={totalAmount}
                        setTotalAmount={setTotalAmount}
                        numTickets={numTickets}
                        setNumTickets={setNumTickets}
                        selectedTickets={selectedTickets}
                        setSelectedTickets={setSelectedTickets}
                        setTicketsHandler={setTicketsHandler}
                      />
                    ))
                  : tickets
                      .sort((a, b) => Number(b.price) - Number(a.price))
                      .map((ticket) => (
                        <TicketItem
                          discount={props.event?.promoCodeDiscount}
                          promoCodeEnabled={promoCodeApplied}
                          ticket={ticket}
                          totalAmount={totalAmount}
                          setTotalAmount={setTotalAmount}
                          numTickets={numTickets}
                          setNumTickets={setNumTickets}
                          selectedTickets={selectedTickets}
                          setSelectedTickets={setSelectedTickets}
                          setTicketsHandler={setTicketsHandler}
                        />
                      ))}

                {/* promo */}
                {true && (
                  <div className="__ticket_promo">
                    <div className="ticket-promo-container">
                      <div className="input-promo-container">
                        <Input
                          placeholder={t("promo_code")}
                          label={t("promo_code")}
                          type="text"
                          id="Promo_Code"
                          onChange={(e) => setEnteredPromoCode(e.target.value)}
                          required
                        />
                      </div>
                      <div className="apply-promo-container">
                        <button
                          className="applyPromo"
                          onClick={() => addPromo()}
                        >
                          {t("add")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {/* end promo */}

                <div className="__ticket-checkout">
                  <div>
                    <div className="subtitle">{t("total_amount")}</div>

                    <h4 className="total-price">{totalAmount} €</h4>
                  </div>
                  {isLoading ? (
                    <LoadingSpinner height="30%" width="30%" />
                  ) : (
                    <div>
                      {totalAmount > 0 ? (
                        <div>
                          <button
                            onClick={() => checkoutHandler("bancontact")}
                            className="checkoutBtn"
                          >
                            {t("pay_with_bancontact")}
                          </button>
                          <button
                            onClick={() => checkoutHandler("card")}
                            className="checkoutBtn"
                          >
                            {t("pay_with_mastercard_visa")}
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() => checkoutHandler("bancontact")}
                            className="checkoutBtn"
                          >
                            {t("confirm")}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <LoadingSpinner height="30%" width="30%" />
            )}

            {/* bottom details */}

            <div className="__bottom_wrapper col-12">
              {eventAddress && placeCoordinates && (
                <div className="__localization-form">
                  <h3 className="__localization-form-title">
                    {t("localization")}
                  </h3>

                  <div className="col-12">
                    <div className="__ticket-location">
                      <div className="__ticket-location-cont">
                        <div className="__ticket-location-innercont">
                          <span className="info">{t("your_address")}</span>
                          <span className="subtitle">{eventAddress}</span>
                        </div>
                        <div className="right-detail">
                          <div className="apply-promo-container">
                            <button
                              onClick={() => showOnMap()}
                              className="applyPromo"
                            >
                              {t("show_on_map")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ticket-map">
                      <APIProvider apiKey={api_key.google_maps_api_key}>
                        <Map
                          defaultCenter={{
                            lat: placeCoordinates.lat,
                            lng: placeCoordinates.lng,
                          }}
                          defaultZoom={17}
                          gestureHandling={"greedy"}
                          disableDefaultUI={false}
                        >
                          <Marker
                            position={{
                              lat: placeCoordinates.lat,
                              lng: placeCoordinates.lng,
                            }}
                          />
                        </Map>
                      </APIProvider>
                    </div>
                  </div>
                </div>
              )}

              <div className="__ticket-calendar">
                {props.event?.eventFromDate && props.event?.eventToDate && (
                  <>
                    <h3 className="__ticket-calendar-title">{t("calendar")}</h3>
                    <div className="__ticket-calendar-items">
                      <div className="__ticket-date">
                        <div className="__ticket-date-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M28.334 4.99935H35.0007C35.4427 4.99935 35.8666 5.17494 36.1792 5.4875C36.4917 5.80006 36.6673 6.22399 36.6673 6.66602V33.3327C36.6673 33.7747 36.4917 34.1986 36.1792 34.5112C35.8666 34.8238 35.4427 34.9993 35.0007 34.9993H5.00065C4.55862 34.9993 4.1347 34.8238 3.82214 34.5112C3.50958 34.1986 3.33398 33.7747 3.33398 33.3327V6.66602C3.33398 6.22399 3.50958 5.80006 3.82214 5.4875C4.1347 5.17494 4.55862 4.99935 5.00065 4.99935H11.6673V1.66602H15.0007V4.99935H25.0007V1.66602H28.334V4.99935ZM6.66732 14.9993V31.666H33.334V14.9993H6.66732ZM10.0007 21.666H18.334V28.3327H10.0007V21.666Z"
                              fill="#8BA4BF"
                            />
                          </svg>
                        </div>
                        <div className="__ticket-date-cont">
                          <span className="info">{t("start")}</span>
                          <span className="subtitle">
                            {moment(
                              new Date(props.event?.eventFromDate)
                            ).format("DD MMM, HH:mm")}
                          </span>
                        </div>
                      </div>
                      <div className="__ticket-date">
                        <div className="__ticket-date-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M28.334 4.99935H35.0007C35.4427 4.99935 35.8666 5.17494 36.1792 5.4875C36.4917 5.80006 36.6673 6.22399 36.6673 6.66602V33.3327C36.6673 33.7747 36.4917 34.1986 36.1792 34.5112C35.8666 34.8238 35.4427 34.9993 35.0007 34.9993H5.00065C4.55862 34.9993 4.1347 34.8238 3.82214 34.5112C3.50958 34.1986 3.33398 33.7747 3.33398 33.3327V6.66602C3.33398 6.22399 3.50958 5.80006 3.82214 5.4875C4.1347 5.17494 4.55862 4.99935 5.00065 4.99935H11.6673V1.66602H15.0007V4.99935H25.0007V1.66602H28.334V4.99935ZM6.66732 14.9993V31.666H33.334V14.9993H6.66732ZM10.0007 21.666H18.334V28.3327H10.0007V21.666Z"
                              fill="#8BA4BF"
                            />
                          </svg>
                        </div>
                        <div className="__ticket-date-cont">
                          <span className="info">{t("end")}</span>
                          <span className="subtitle">
                            {moment(new Date(props.event?.eventToDate)).format(
                              "DD MMM, HH:mm"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <h3 className="__ticket-calendar-title">{t("contact")}</h3>
                {/* organization */}
                {organization !== null && (
                  <div className="__ticket-contact-details">
                    <div className="__ticket-contact-area">
                      <div className="__ticket-contact-area-container">
                        <div className="__ticket-contact-area-image">
                          <img
                            src={organization.org.logo || default_org}
                            alt={t("logo")}
                          />
                        </div>
                        <div className="__ticket-contact-area-title">
                          <div className="title">
                            {organization.org.organization}
                          </div>
                          <div className="email">
                            {organization.org.adminEmail !== null &&
                              organization.org.adminEmail.length > 0 &&
                              organization.org.adminEmail[0]}
                          </div>
                        </div>
                      </div>
                      <div className="__ticket-contact-area-button">
                        {organization.org.adminEmail &&
                        organization.org.adminEmail.length > 0 ? (
                          <a
                            style={{ textDecoration: "none" }}
                            href={`mailto:${organization.org.adminEmail[0]}`}
                          >
                            <button className="contactBtn">
                              {t("contact")}
                            </button>
                          </a>
                        ) : (
                          <button className="contactBtn" disabled>
                            {t("no_contact_available")}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* end organization */}

                {/* share */}
                <div className="__ticket-contact-social">
                  <div className="__ticket-social-area">
                    <div className="__ticket-social-area-title">
                      <div className="title">{t("share_event_on_socials")}</div>
                    </div>
                    <div className="__ticket-social-area-buttons">
                      <img
                        onClick={() => openSocial(socialLinks.facebook)}
                        src={facebook}
                        alt="Facebook"
                      />
                      <img
                        onClick={() => openSocial(socialLinks.instagram)}
                        src={instagram}
                        alt="Instagram"
                      />
                      <img
                        onClick={() => openSocial(socialLinks.twitter)}
                        src={twitter}
                        alt="Twitter"
                      />
                      <img
                        onClick={() => openSocial(socialLinks.youtube)}
                        src={youtube}
                        alt="Youtube"
                      />
                    </div>
                  </div>
                </div>
                {/* end share */}
              </div>
            </div>

            {/* end bottom details */}
          </div>
        )}
      </div>
    </section>
  );
};

export default EventDesc;
