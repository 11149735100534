import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import flagUsa from "./img/flag-usa.png";
import flagNl from "./img/flag-nl.png";
import flagFr from "./img/flag-france.png";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const currentLanguage = i18n.language;

  return (
    <div className="languageDropdownWrapper">
      <Dropdown className="languageDropdown">
        <Dropdown.Toggle id="dropdown-language-toggle">
          <span
            className={
              "flagIcon rounded-circle d-flex align-items-center justify-content-center"
            }
          >
            <img
              src={
                currentLanguage === "en"
                  ? flagUsa
                  : currentLanguage === "fr"
                  ? flagFr
                  : currentLanguage === "nl"
                  ? flagNl
                  : null
              }
              className="w-100 h-100"
              alt="flag"
            />
          </span>
          {currentLanguage === "en"
            ? "English"
            : currentLanguage === "fr"
            ? "Français"
            : currentLanguage === "nl"
            ? "Nederlands"
            : "Select Language"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => changeLanguage("en")}>
            English
          </Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage("fr")}>
            Français
          </Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage("nl")}>
            Nederlands
          </Dropdown.Item>
          {/* Add more languages as needed */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default LanguageSwitcher;
