import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";

const TicketItem = (props) => {
  const { t } = useTranslation('ticketitem');
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(props.ticket.price);
  const [promoPrice, setPromoPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const { discount, promoCodeEnabled } = props;

  const alert = useAlert();

  const promoCodeActive = promoCodeEnabled === props.ticket?.promoCodeName;

  const numberLeft = props.ticket.numAvailable - props.ticket.amountSold;

  useEffect(() => {
    let updatedPrice = props.ticket.price;
    if (promoCodeActive) {
      updatedPrice = parseFloat(
        ((100 - parseFloat(props.ticket?.promoCodeDiscount)) / 100) *
          props.ticket.price
      );
    }
    if (quantity > 0) {
      const newTotal = parseFloat(updatedPrice * quantity);
      setTotal(newTotal);

      const event = { target: { value: quantity } };
      updateQuantity(event, updatedPrice);
    }
    setPrice(updatedPrice);
  }, [promoCodeEnabled]);

  const decrement = () => {
    if (quantity === 0) {
      setQuantity(0);
    } else {
      const ticketIndex = props.selectedTickets.findIndex(
        (item) => item.ticketId === props.ticket.id
      );
      if (ticketIndex >= 0 && props.selectedTickets[ticketIndex].number > 1) {
        const newArray = props.selectedTickets;
        newArray[ticketIndex] = {
          ticketId: props.ticket.id,
          name: props.ticket.name,
          number: newArray[ticketIndex].number - 1,
          sum: newArray[ticketIndex].sum - price,
        };
        props.setSelectedTickets(newArray);
      } else if (
        ticketIndex >= 0 &&
        props.selectedTickets[ticketIndex].number === 1
      ) {
        const newArray = props.selectedTickets;
        newArray.splice(ticketIndex, 1);
      }
      const newTotal = price * quantity;
      setTotal(newTotal);
      props.setTotalAmount(
        parseFloat(
          (Math.round((props.totalAmount - price) * 100) / 100).toFixed(2)
        )
      );
      setQuantity(quantity - 1);
      props.setNumTickets(props.numTickets - 1);
    }
  };

  const increment = () => {
    if (quantity < props.ticket.maxUnitPerSale) {
      const ticketIndex = props.selectedTickets.findIndex(
        (item) => item.ticketId === props.ticket.id
      );

      const newArray = props.selectedTickets;
      if (ticketIndex >= 0) {
        //Already in basket
        if (parseInt(newArray[ticketIndex].number) + 1 > numberLeft) {
          //Max left reached
          alert.show(
            `${props.ticket.name} ${t('tickets_left', { count: numberLeft })}`,
            { type: "info" }
          );
        } else {
          newArray[ticketIndex] = {
            ticketId: props.ticket.id,
            name: props.ticket.name,
            number: parseInt(newArray[ticketIndex].number) + 1,
            sum: parseFloat(newArray[ticketIndex].sum) + price,
          };
          props.setSelectedTickets(newArray);
          setQuantity(parseInt(quantity) + 1);
          props.setNumTickets(parseInt(props.numTickets) + 1);
          const newTotal = price * quantity;
          setTotal(newTotal);
          props.setTotalAmount(
            parseFloat(
              (Math.round((props.totalAmount + price) * 100) / 100).toFixed(2)
            )
          );
        }
      } else {
        newArray.push({
          ticketId: props.ticket.id,
          name: props.ticket.name,
          number: quantity + 1,
          sum: props.ticket.price,
        });
        props.setSelectedTickets(newArray);
        setQuantity(quantity + 1);
        props.setNumTickets(props.numTickets + 1);
        setTotal(price * quantity);
        const newTotal = price * quantity;
        setTotal(newTotal);
        props.setTotalAmount(
          parseFloat(
            (Math.round((props.totalAmount + price) * 100) / 100).toFixed(2)
          )
        );
      }
    } else {
      alert.show(
        `${t('maximum_tickets', { max: props.ticket.maxUnitPerSale })}`,
        { type: "info" }
      );
    }
  };

  const updateQuantity = (event, newPrice) => {
    const newQuantity = event.target.value;

    const selectedPrice = newPrice ? newPrice : price;

    const ticketIndex = props.selectedTickets.findIndex(
      (item) => item.ticketId === props.ticket.id
    );
    if (newQuantity && newQuantity >= 0) {
      if (newQuantity <= props.ticket.maxUnitPerSale) {
        const newArray = props.selectedTickets;
        if (ticketIndex >= 0) {
          //Already in basket
          if (newQuantity > numberLeft) {
            //Max left reached
            alert.show(
              `${props.ticket.name} ${t('tickets_left', { count: numberLeft })}`,
              { type: "info" }
            );
          } else {
            newArray[ticketIndex] = {
              ticketId: props.ticket.id,
              name: props.ticket.name,
              number: parseInt(newQuantity),
              sum: newQuantity * selectedPrice,
            };
            props.setTicketsHandler(newArray);
            setQuantity(newQuantity);

            const newTotal = selectedPrice * newQuantity;
            setTotal(newTotal);
          }
        } else {
          newArray.push({
            ticketId: props.ticket.id,
            name: props.ticket.name,
            number: parseInt(newQuantity),
            sum: newQuantity * selectedPrice,
          });
          props.setTicketsHandler(newArray);
          setQuantity(newQuantity);

          const newTotal = selectedPrice * newQuantity;
          setTotal(newTotal);
        }
      } else {
        alert.show(
          `${t('maximum_tickets', { max: props.ticket.maxUnitPerSale })}`,
          { type: "info" }
        );
      }
    } else {
      const newArray = props.selectedTickets;
      if (ticketIndex >= 0) {
        //Already in basket
        newArray.splice(ticketIndex, 1);

        props.setTicketsHandler(newArray);

        setQuantity(newQuantity < 0 ? 0 : newQuantity);
        setTotal(0);
      } else {
        setQuantity(newQuantity < 0 ? 0 : newQuantity);
        setTotal(0);
      }
    }
  };

  return (
    <div className="__ticket-item">
      <div className="__ticket-item-icon">
        <svg viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_3_129)">
            <path
              d="M35.0002 5.875C35.4422 5.875 35.8661 6.05059 36.1787 6.36316C36.4912 6.67572 36.6668 7.09964 36.6668 7.54167V16.7083C35.5618 16.7083 34.502 17.1473 33.7206 17.9287C32.9392 18.7101 32.5002 19.7699 32.5002 20.875C32.5002 21.9801 32.9392 23.0399 33.7206 23.8213C34.502 24.6027 35.5618 25.0417 36.6668 25.0417V34.2083C36.6668 34.6504 36.4912 35.0743 36.1787 35.3868C35.8661 35.6994 35.4422 35.875 35.0002 35.875H5.00016C4.55814 35.875 4.13421 35.6994 3.82165 35.3868C3.50909 35.0743 3.3335 34.6504 3.3335 34.2083V25.0417C4.43856 25.0417 5.49837 24.6027 6.27977 23.8213C7.06118 23.0399 7.50016 21.9801 7.50016 20.875C7.50016 19.7699 7.06118 18.7101 6.27977 17.9287C5.49837 17.1473 4.43856 16.7083 3.3335 16.7083V7.54167C3.3335 7.09964 3.50909 6.67572 3.82165 6.36316C4.13421 6.05059 4.55814 5.875 5.00016 5.875H35.0002ZM33.3335 9.20833H6.66683V14.155L6.92683 14.29C8.05736 14.9067 9.01004 15.8042 9.69311 16.8959C10.3762 17.9877 10.7666 19.2369 10.8268 20.5233L10.8335 20.875C10.8337 22.2221 10.471 23.5443 9.78358 24.7028C9.09614 25.8612 8.10931 26.8131 6.92683 27.4583L6.66683 27.595V32.5417H33.3335V27.5933L33.0735 27.46C31.943 26.8433 30.9903 25.9458 30.3072 24.8541C29.6242 23.7623 29.2337 22.5131 29.1735 21.2267L29.1668 20.875C29.1668 18.035 30.7452 15.5633 33.0735 14.2917L33.3335 14.1533V9.20833ZM26.6668 15.875V25.875H13.3335V15.875H26.6668Z"
              fill="#8BA4BF"
            />
          </g>
          <defs>
            <clipPath id="clip0_3_129">
              <rect
                width="40"
                height="40"
                fill="white"
                transform="translate(0 0.875)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="__ticket-item-cont">
        <div className="">
          {numberLeft > 0 ? (
            <span className="info">{t('available')}</span>
          ) : (
            <span className="info">{t('not_available')}</span>
          )}
          <h6 className="subtitle">{props.ticket.name}</h6>
          {numberLeft > 0 ? (
            <span className={promoCodeActive ? "promo-item-price" : "price"}>
              {price.toFixed(2)} €
            </span>
          ) : (
            <span className="price">-</span>
          )}
        </div>
        {numberLeft > 0 && (
          <div className="right-detail">
            <input
              type="number"
              className="quantity"
              value={quantity}
              onChange={updateQuantity}
              pattern="^-?[0-9]\d*\.?\d*$"
            />
            <div className="qty-btns">
              <div className="qty-btn" onClick={increment}>
                <svg
                  width="26"
                  height="23"
                  viewBox="0 0 26 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.935 10.3828V4.80469H14.0647V10.3828H20.4536V12.2422H14.0647V17.8203H11.935V12.2422H5.54614V10.3828H11.935Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="qty-btn" onClick={decrement}>
                <svg
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.935 11.0078H14.0647H20.4536V12.8672H14.0647H11.935H5.54614V11.0078H11.935Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketItem;
