import React, { useEffect, useState } from "react";
import {
  Text,
  Font,
  Page,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import QRCode from "qrcode";

import Header from "./Header";
import Skills from "./Skills";
import Education from "./Education";
import Details from "./Details";
import logo from "../img/comitty-logo.png";
import logo2 from "../img/Comitty_horizontal.png";
import sncb from "../img/sncb.jpg";
import kikk from "../img/kikk.png";
import kikk_logo from "../img/kikk_logo.jpg";
import {ResumeFr, ResumeEn, ResumeNl} from "./Resume";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: "row",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  image: {
    marginBottom: 10,
  },
  promo: {
    alignItems: "center",
    width: "100%",
  },
  logo: {
    width: 24,
  },
  logo2: {
    width: 124,
  },
  message: {
    textAlign: "center",
    fontSize: 9,
    fontFamily: "Poppins",
  },
  code: {
    textAlign: "center",
    fontSize: 9,
    fontFamily: "Poppins Bold",
  },
  leftColumn: {
    flexDirection: "column",
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
    "@media orientation: landscape": {
      width: 200,
    },
  },
  footer: {
    fontSize: 10,
    fontFamily: "Poppins Bold",
    textAlign: "center",
    marginTop: 15,
    paddingTop: 5,
    borderWidth: 3,
    borderColor: "gray",
    borderStyle: "dashed",
    "@media orientation: landscape": {
      marginTop: 10,
    },
  },
  footer2: {
    width: "100%",
    fontSize: 8,
    fontFamily: "Poppins Bold",
    textAlign: "center",
    marginVertical: 5,
    paddingTop: 5,
    borderWidth: 3,
    borderColor: "gray",
    borderStyle: "dashed",
    "@media orientation: landscape": {
      marginTop: 10,
    },
  },
});

Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Poppins",
  src: `https://fonts.gstatic.com/s/poppins/v1/VIeViZ2fPtYBt3B2fQZplvesZW2xOQ-xsNqO47m55DA.ttf`,
});

Font.register({
  family: "Poppins Italic",
  src: `https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf`,
});

Font.register({
  family: "Poppins Bold",
  src: `https://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf`,
});





const TicketModel = (props) => {
  const { lang } = props; // Assuming lang is passed as a prop

  const ResumeComponent =
    lang === "fr" ? ResumeFr : lang === "nl" ? ResumeNl : ResumeEn;

  if (props.subdivision) {
    return (
      <Document
        author="Comitty"
        keywords="comitty, ticket, event"
        subject={"Vos tickets pour " + props.eventName}
        title="Tickets"
      >
        {props.tickets.map((ticket, index) => (
          <ResumeComponent
            size="A4"
            qrcode={props.id[index]}
            eventImage={logo}
            details={props.details}
            eventName={props.eventName}
            tickets={[ticket]}
            eventDescription={props?.eventDescription}
            eventBannerUrl={props?.eventBannerUrl}
            eventLogoUrl={props?.eventLogoUrl}
            sncbPromo={props?.sncbPromo ? props?.sncbPromo[index] : false}
            lang={lang}
          />
        ))}
      </Document>
    );
  }

  return (
    <Document
      author="Comitty"
      keywords="comitty, ticket, event"
      subject={"Vos tickets pour " + props.eventName}
      title="Tickets"
    >
      <ResumeComponent
        size="A4"
        qrcode={props.id}
        eventImage={logo}
        tickets={props.tickets}
        details={props.details}
        eventName={props.eventName}
        eventDescription={props?.eventDescription}
        eventBannerUrl={props?.eventBannerUrl}
        eventLogoUrl={props?.eventLogoUrl}
        sncbPromo={props?.sncbPromo}
        lang={lang}
      />
    </Document>
  );
};

export default TicketModel;
