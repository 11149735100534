import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Bar from "./icons/Bar";
import Close from "./icons/Close";
import logo from "./img/logo.svg";
import { NavLink } from "react-router-dom";
import LanguageSwitcher from "../components/LanguageSwitcher";

const Header = () => {
  const { t } = useTranslation("header");
  const [menuOpen, setMenuOpen] = useState(false);
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position < 100) {
      setSticky(false);
    } else {
      setSticky(true);
    }
  };

  window.addEventListener("scroll", handleScroll);
  return (
    <>
      <header className={`${sticky ? "sticky " : ""}`}>
        <div className="container">
          <div className="header-wrapper d-flex justify-content-between align-items-center">
            <a href="https://comitty.eu" className="logo">
              <img src={logo} alt={t('logo_alt')} />
            </a>
            <div
              className="header-bar d-md-none"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? <Close /> : <Bar />}
            </div>
            <div className={`menu-area ${menuOpen ? "active" : ""}`}>
              <ul className="mb-0 menu">
                <li>
                  <a href="https://comitty.be/blog/">{t('our_blog')}</a>
                </li>
                <li>
                  <a href="https://comitty.eu">{t('learn_more')}</a>
                </li>
                {/* <li>
<NavLink to="/login">{t('login')}</NavLink>
</li> */}
              </ul>
              {/* <div className="login-btns">
<a href="#0" className="header-btn btn-outline">
<span>{t('login')}</span>
</a>
</div> */}
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
