import React, { useState, useEffect } from "react";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { pdf } from "@react-pdf/renderer";
import QRCode from "qrcode";
import { db } from "../firebase";
import ENV from "../env";

import Input from "./Input";
import TicketItem from "./TicketItem";
import LoadingSpinner from "../components/LoadingSpinner";
import TicketSpinner from "../components/TicketSpinner";
import ErrorSpinner from "../components/ErrorSpinner";
import {
  sendQRCode,
  updateParticipant,
  getPromoCode,
  translateText,
} from "../utility";
import TicketModel from "./pdf";

const Checkout = (props) => {
  const { t, i18n } = useTranslation("checkout");
  const eventId = props.event.id;
  const participant = props.participant;
  const paymentStatus = props.paymentStatus;
  const [ticketFile, setTicketFile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isMailSent, setIsMailSent] = useState(participant.paid ? true : false);
  const [ticketQr, setTicketQr] = useState();
  const alert = useAlert();

  const sendTickets = async () => {
    setIsLoading(true);

    let sncbPromoCode = [];

    if (paymentStatus === "success" && eventId) {
      const participantName = participant.details.find(
        (element) => element.name === "Firstname"
      );
      const participantMail = participant.details.find(
        (element) => element.name === "Email"
      );

      let translatedDescription = props.event.description;
      if (props.event?.descEN) {
        switch (i18n.language) {
          case "en":
            translatedDescription = props.event.descEN;
            break;
          case "fr":
            translatedDescription = props.event.descFR;
            break;
          case "nl":
            translatedDescription = props.event.descNL;
            break;
          default:
            translatedDescription = props.event.descEN;
        }
      }

      let blobPDF;
      if (participant.subdivision) {
        const qrcodes = [];
        let individualTickets = [];
        for (const index in participant.tickets) {
          for (let i = 0; i < participant.tickets[index].number; i++) {
            individualTickets.push({
              name: participant.tickets[index].name,
              number: 1,
              sum:
                participant.tickets[index].sum /
                participant.tickets[index].number,
              ticketId: participant.tickets[index].ticketId,
            });
          }
        }

        for (const index in individualTickets) {
          if (props.event?.sncbPromo) {
            console.log("sncbPromo is true");
            const fetchedCode = await getPromoCode(
              participant.id + index.toString(),
              eventId
            );
            sncbPromoCode.push(fetchedCode);
          } else {
            console.log("sncbPromo is false");
          }
          const qrcode = await QRCode.toDataURL(
            participant.id + index.toString(),
            { scale: 8 }
          );
          qrcodes.push(qrcode);
        }
        console.log("PROMO COODES");
        console.log(sncbPromoCode);
        setTicketQr(qrcodes[0]);
        blobPDF = await pdf(
          TicketModel({
            id: qrcodes,
            eventName: props.event.name,
            eventDescription: translatedDescription,
            tickets: individualTickets,
            details: participant.details,
            sncbPromo: sncbPromoCode.length > 0 ? sncbPromoCode : false,
            subdivision: true,
            lang: i18n.language,
          })
        ).toBlob();
      } else {
        if (props.event?.sncbPromo) {
          const fetchedCode = await getPromoCode(participant.id, eventId);
          sncbPromoCode.push(fetchedCode);
        }
        const qrcode = await QRCode.toDataURL(participant.id, { scale: 8 });
        setTicketQr(qrcode);
        blobPDF = await pdf(
          TicketModel({
            id: qrcode,
            eventName: props.event.name,
            eventDescription: translatedDescription,
            tickets: participant.tickets,
            details: participant.details,
            sncbPromo: sncbPromoCode.length > 0 ? sncbPromoCode[0] : false,
            lang: i18n.language,
          })
        ).toBlob();
      }

      setTicketFile(blobPDF);

      if (!isMailSent) {
        var reader = new FileReader();
        reader.readAsDataURL(blobPDF);
        reader.onloadend = function () {
          var base64data = reader.result;
          const params = {
            from_name: props.event.name,
            to_name: participantName.value,
            to_email: participantMail.value,
            content: base64data,
          };
          const emailSent = sendQRCode(params);
          setIsMailSent(true);
        };
        await updateParticipant(participant)
          .then(async (success) => {
            alert.show(t("registration_successful"), { type: "success" });
          })
          .catch((err) => {
            alert.show(t("registration_failed"), {
              type: "error",
            });
          });
      }
    } else if (paymentStatus !== "success") {
      alert.show(t("registration_failed"), {
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const downloadTickets = async () => {
    if (ticketFile) {
      const url = URL.createObjectURL(ticketFile);
      window.open(url);
    } else {
      window.open(`${ENV.hostname}/events/${eventId}`, "_parent", "noreferrer");
    }
  };

  useEffect(() => {
    sendTickets();
  }, []);

  return (
    <section className="event-desc-section">
      <div className="container">
        {isLoading ? (
          <div className="section-header">
            <h2 className="title">{t("loading")}</h2>
            <LoadingSpinner height="30%" width="30%" />
          </div>
        ) : (
          <div className="section-header">
            <h2 className="title">
              {paymentStatus === "success"
                ? t("registration_successful")
                : t("transaction_failed")}
            </h2>
            {/* 
            {paymentStatus === "success" ? (
              <img src={ticketQr} alt="" className="qrcode" />
            ) : (
              <ErrorSpinner height="30%" width="30%" />
            )} */}

            {paymentStatus !== "success" && (
              <ErrorSpinner height="30%" width="30%" />
            )}
            {paymentStatus === "success" && (
              <h2 className="title">{t("download_tickets_now")}</h2>
            )}
            <div className="text">
              {paymentStatus === "success"
                ? t("registration_message")
                : t("payment_failed_message")}
            </div>

            <div className="col-12">
              <button
                type="submit"
                className="submitBtn"
                onClick={downloadTickets}
              >
                {paymentStatus === "success"
                  ? t("download_tickets")
                  : t("retry")}
              </button>
            </div>
            {/* {paymentStatus === "success" && (
              <div className="col-12">
                <button
                  type="submit"
                  className="submitBtn"
                  onClick={() => {
                    window.open(`https://comitty.be`, "_parent", "noreferrer");
                  }}
                >
                  {t("learn_more_about_comitty")}
                </button>
              </div>
            )} */}
          </div>
        )}
      </div>
    </section>
  );
};

export default Checkout;
