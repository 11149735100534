/* eslint-disable react/no-array-index-key */

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

import Title from "./Title";
import List, { Item } from "./List";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 30,
    paddingLeft: 15,
    "@media max-width: 400": {
      paddingTop: 10,
      paddingLeft: 0,
    },
  },
  entryContainer: {
    marginBottom: 10,
  },
  date: {
    fontSize: 10,
    fontFamily: "Poppins Italic",
  },
  detailContainer: {
    flexDirection: "row",
  },
  detailLeftColumn: {
    flexDirection: "column",
    marginLeft: 10,
    marginRight: 10,
  },
  detailRightColumn: {
    flexDirection: "column",
    flexGrow: 9,
  },
  bulletPoint: {
    fontSize: 8,
  },
  details: {
    fontSize: 8,
    fontFamily: "Poppins",
  },
  headerContainer: {
    flexDirection: "row",
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: "column",
    flexGrow: 9,
  },
  rightColumn: {
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-end",
    justifySelf: "flex-end",
  },
  title: {
    fontSize: 19,
    color: "black",
    textDecoration: "none",
    fontFamily: "Poppins Bold",
  },
  description: {
    fontSize: 10,
    color: "black",
    textDecoration: "none",
    fontFamily: "Poppins",
    marginBottom: 5,
  },
});

const ExperienceEntry = ({ company, details, position, date }) => {
  const title = `${company}: `;
  return (
    <View style={styles.entryContainer}>
      <View style={styles.headerContainer}>
        <View style={styles.leftColumn}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.date}>{date}</Text>
        </View>
      </View>
      <List>
        {details.map((detail, i) => (
          <Item key={i} style={styles.detailContainer}>
            {detail}
          </Item>
        ))}
      </List>
    </View>
  );
};

const experienceData = [
  {
    company: "Vos tickets",
    date: "",
    details: ["1 x Standard", "2 x VIP"],
  },
  {
    company: "Rebel Alliance",
    date: "A long time ago...",
    details: [
      "Lead legions of troops into battle while demonstrating bravery, competence and honor",
      "Created complicated battle plans in conjunction with other Rebel leaders in order to ensure the greatest chance of success",
      "Defeated Darth Vader in single-combat, and convinced him to betray his mentor, the Emperor",
    ],
    position: "General",
  },
  {
    company: "Rebel Alliance",
    date: "A long time ago...",
    details: [
      "Destroyed the Death Star by using the force to find its only weakness and delivering a torpedo into the center of the ship",
      "Commanded of squadron of X-Wings into battle",
      "Defeated an enemy AT-AT single handedly after his ship was destroyed",
      "Awarded a medal for valor and bravery in battle for his successful destruction of the Death Star",
    ],
    position: "Lieutenant Commander",
  },
  {
    company: "Tatooine Moisture Refinery",
    date: "A long time ago...",
    details: [
      "Replaced damaged power converters",
      "Performed menial labor thoughout the farm in order to ensure its continued operation",
    ],
    position: "Moisture Farmer",
  },
];

const Experience = (props) => {
  const title =
    props.lang === "fr"
      ? "Merci pour votre achat!"
      : props.lang === "nl"
      ? "Bedankt voor uw aankoop!"
      : "Thank you for your purchase!";

  const ticketsLabel =
    props.lang === "fr"
      ? "Vos Tickets"
      : props.lang === "nl"
      ? "Uw Tickets"
      : "Your Tickets";

  const detailsLabel =
    props.lang === "fr"
      ? "Vos Détails"
      : props.lang === "nl"
      ? "Uw Détails"
      : "Your Details";

  return (
    <View style={styles.container}>
      <Text>{title}</Text>
      {props?.description && (
        <Text style={styles.description}>{props?.description}</Text>
      )}
      {props.tickets && (
        <ExperienceEntry
          company={ticketsLabel}
          date={""}
          details={props.tickets.map((ticket) => {
            return ticket.number + " x " + ticket.name + " " + ticket.sum + "€";
          })}
          key={10}
          position={""}
        />
      )}
      {props.details && (
        <ExperienceEntry
          company={detailsLabel}
          date={""}
          details={props.details.map((detail) => {
            return detail.name + ": " + detail.value;
          })}
          key={11}
          position={""}
        />
      )}
    </View>
  );
};

export default Experience;
