import React from "react";
import { Page, View, Image, Text, StyleSheet } from "@react-pdf/renderer";
import Header from "./Header"; // Assuming you have a Header component
import Details from "./Details"; // Assuming you have a Details component
import logo from "../img/comitty-logo.png";
import logo2 from "../img/Comitty_horizontal.png";
import sncb from "../img/sncb.jpg";
import kikk from "../img/kikk.png";
import kikk_logo from "../img/kikk_logo.jpg";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: "row",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  image: {
    marginBottom: 10,
  },
  promo: {
    alignItems: "center",
    width: "100%",
  },
  logo: {
    width: 24,
  },
  logo2: {
    width: 124,
  },
  message: {
    textAlign: "center",
    fontSize: 9,
    fontFamily: "Poppins",
  },
  code: {
    textAlign: "center",
    fontSize: 9,
    fontFamily: "Poppins Bold",
  },
  leftColumn: {
    flexDirection: "column",
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
    "@media orientation: landscape": {
      width: 200,
    },
  },
  footer: {
    fontSize: 10,
    fontFamily: "Poppins Bold",
    textAlign: "center",
    marginTop: 15,
    paddingTop: 5,
    borderWidth: 3,
    borderColor: "gray",
    borderStyle: "dashed",
    "@media orientation: landscape": {
      marginTop: 10,
    },
  },
  footer2: {
    width: "100%",
    fontSize: 8,
    fontFamily: "Poppins Bold",
    textAlign: "center",
    marginVertical: 5,
    paddingTop: 5,
    borderWidth: 3,
    borderColor: "gray",
    borderStyle: "dashed",
    "@media orientation: landscape": {
      marginTop: 10,
    },
  },
});

export const ResumeFr = (props) => (
  <Page {...props} style={styles.page}>
    <Header title={props.eventName} />
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        {props.qrcode && (
          <Image
            src={props?.sncbPromo ? kikk_logo : logo}
            style={styles.image}
          />
        )}
        {props.qrcode && <Image src={props.qrcode} style={styles.image} />}
      </View>
      {props.tickets && (
        <Details
          tickets={props.tickets}
          details={props.details}
          description={props?.eventDescription}
          lang={props?.lang}
        />
      )}
    </View>
    {props?.sncbPromo ? (
      <View style={styles.promo}>
        <Image src={sncb} style={styles.logo} />
        <Text style={styles.message}>
          {`Visitez le ${props.eventName} en train! Achetez un Discovery Ticket avec le code SNCB sur sncb.be/discovery et voyagez A/R vers Namur avec 50% de réduction.`}
        </Text>
        <Text style={styles.code}>{`Votre code est: ${props?.sncbPromo}`}</Text>
        <Image src={kikk} style={styles.image} />
        <Text style={styles.footer2}>
          La version numérique suffit, n'imprimez ce document que si nécessaire.
        </Text>
        <Image src={logo2} style={styles.logo2} />
      </View>
    ) : (
      <Text style={styles.footer}>
        La version numérique suffit, n'imprimez ce document que si nécessaire.
      </Text>
    )}
  </Page>
);

export const ResumeNl = (props) => (
  <Page {...props} style={styles.page}>
    <Header title={props.eventName} />
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        {props.qrcode && (
          <Image
            src={props?.sncbPromo ? kikk_logo : logo}
            style={styles.image}
          />
        )}
        {props.qrcode && <Image src={props.qrcode} style={styles.image} />}
      </View>
      {props.tickets && (
        <Details
          tickets={props.tickets}
          details={props.details}
          description={props?.eventDescription}
          lang={props?.lang}
        />
      )}
    </View>
    {props?.sncbPromo ? (
      <View style={styles.promo}>
        <Image src={sncb} style={styles.logo} />
        <Text style={styles.message}>
          {`Bezoek het ${props.eventName} met de trein! Koop een Discovery Ticket met de SNCB-code op sncb.be/discovery en reis retour naar Namen met 50% korting.`}
        </Text>
        <Text style={styles.code}>{`Uw code is: ${props?.sncbPromo}`}</Text>
        <Image src={kikk} style={styles.image} />
        <Text style={styles.footer2}>
          De digitale versie is voldoende, print dit document alleen als het
          nodig is.
        </Text>
        <Image src={logo2} style={styles.logo2} />
      </View>
    ) : (
      <Text style={styles.footer}>
        De digitale versie is voldoende, print dit document alleen als het nodig
        is.
      </Text>
    )}
  </Page>
);

export const ResumeEn = (props) => (
  <Page {...props} style={styles.page}>
    <Header title={props.eventName} />
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        {props.qrcode && (
          <Image
            src={props?.sncbPromo ? kikk_logo : logo}
            style={styles.image}
          />
        )}
        {props.qrcode && <Image src={props.qrcode} style={styles.image} />}
      </View>
      {props.tickets && (
        <Details
          tickets={props.tickets}
          details={props.details}
          description={props?.eventDescription}
          lang={props?.lang}
        />
      )}
    </View>
    {props?.sncbPromo ? (
      <View style={styles.promo}>
        <Image src={sncb} style={styles.logo} />
        <Text style={styles.message}>
          {`Visit the ${props.eventName} by train! Buy a Discovery Ticket with the SNCB code on sncb.be/discovery and travel round trip to Namur with a 50% discount.`}
        </Text>
        <Text style={styles.code}>{`Your code is: ${props?.sncbPromo}`}</Text>
        <Image src={kikk} style={styles.image} />
        <Text style={styles.footer2}>
          The digital version is sufficient, only print this document if
          necessary.
        </Text>
        <Image src={logo2} style={styles.logo2} />
      </View>
    ) : (
      <Text style={styles.footer}>
        The digital version is sufficient, only print this document if
        necessary.
      </Text>
    )}
  </Page>
);
