//PROD
const vars = {
  firebaseApiKey: "AIzaSyCXZNPbGtOQqDg4lDKP5HcGpuvfcj0laVw",
  firebaseApiUrl: "https://europe-west1-comitty-79c84.cloudfunctions.net",
  woosmapPublicKey: "woos-0952c97d-1abb-3a64-8860-be2f95fd0253",
  woosmapPrivateKey: "cf0d09a6-071b-41dd-92b6-3feff8842349",
  hostname: "https://ticket.comitty.be",
  google_maps_api_key: "AIzaSyBZF9TSh1krn9Emrr4eOpl1K2KupgHybP8",
  // hostname: 'http://192.168.0.105:3000',
  google_translate_api_key: "AIzaSyB7I6sfwYoiASPuEtb4py4N9Ta6DWcl0A8",
};

//DEV
// const vars = {
//     firebaseApiKey: 'AIzaSyCXZNPbGtOQqDg4lDKP5HcGpuvfcj0laVw',
//     firebaseApiUrl: 'https://europe-west1-yummi-events.cloudfunctions.net',
//     woosmapPublicKey: 'woos-0952c97d-1abb-3a64-8860-be2f95fd0253',
//     woosmapPrivateKey: 'cf0d09a6-071b-41dd-92b6-3feff8842349',
//     hostname: 'https://yummi-events.web.app'
// };

export default vars;
