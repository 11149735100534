import ENV from "../env";

const getPromoCode = async (participantId, eventId) => {
  const response = await fetch(
    `${ENV.firebaseApiUrl}/participant/${participantId}/code/${eventId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const resData = await response.json();

  if (resData.code) {
    return resData.code;
  } else {
    console.error("Get organization's order failed: " + resData?.error);
    return false;
  }
};

export default getPromoCode;
